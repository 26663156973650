<template>
    <div>
        <div class="flex items-center md:items-end mb-10">
            <slot name="title">
                <UIHeading >
                    <slot name="title-text"/>
                </UIHeading>
            </slot>

            <ULink class="hidden md:inline-flex ml-auto font-bold text-blue-400 underline hover:no-underline hover:text-blue-500 h-11 items-center" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
        </div>

        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-10 items-start">
            <div v-for="service of services" class="grid gap-3" :key="service._path">
                <h2 class="text-xl font-bold">
                    <ULink :to="service._path" class="flex items-center gap-3 hover:text-blue-700">
                        <UIIcon v-if="service.icon" :name="service.icon" class="text-blue-400" />
                        {{service.title}}
                    </ULink>
                </h2>
                <div>{{service.shortDescription}}</div>
            </div>

            <div class="flex justify-center">
                <ULink class="md:hidden font-bold text-blue-400 underline hover:no-underline hover:text-blue-500" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
            </div>
        </div>

        <div class="flex flex-col gap-6 justify-center mt-10 md:mt-12" v-if="bottomBlock">
            <div class="font-xl text-center font-semibold">Contact us, and we’ll help you pick the best time for your service!</div>

            <div class="text-center">
                <UButton size="xl" variant="solid">Get Assistance Now</UButton>
            </div>
        </div>
    </div>
</template>

<script setup>
const {limit} = defineProps({
    readMoreLink: {
        type: String
    },
    limit: {
        type: Number,
        default: null
    },
    bottomBlock: {
        type: Boolean,
        default: false
    }
});

const { data: services } = await useAsyncData(() =>
    queryContent('services')
        .where({
            _dir: { $eq: 'services'}
        })
        .sort({order: 1})
        .limit(limit)
        .only(['title', 'shortDescription', '_path', 'icon'])
        .find()
);
</script>
